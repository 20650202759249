.carousel__dot {
  width: 50px;
  margin-right: 6px;
  border-radius: 100px;
  background-color: #CAF5F9;
  border: 2px solid #CAF5F9;
}

.carousel__dot--selected {
  background-color: #22B8CF;
  border: 2px solid #22B8CF;
}
.slick-dots li
{ 
    width: 14px!important;
}